import React from 'react';

import * as S from './styles';

const AboutHero = (): JSX.Element => (
  <S.Section>
    <S.Title>“Lorem ipsum dolor sit amet, consectetur adipiscing elit”</S.Title>
    <S.Text>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc at venenatis
      egestas amet. Vitae aliquam blandit arcu, amet urna, tincidunt.
      Pellentesque velit eget diam adipiscing neque, elit arcu. Nisl, mi
      facilisis porta eu, euismod id molestie auctor nulla.
    </S.Text>
  </S.Section>
);

export default AboutHero;
