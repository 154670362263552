import styled from 'styled-components';

export const Single = styled.div`
    padding: 30px;
    width: 100%;
    height: 280px;
    display: flex;
    background-color: #ffffff;
    justify-content: space-between;
    align-items: center;
    gap: 32px;
    border-radius: 0;
    box-shadow: 0px 10px 25px rgba(204, 216, 234, 0.29),
        0px 20px 50px rgba(221, 229, 243, 0.2);

    @media (max-width: 768px) {
        width: 100%;
        padding: 20px;
    }
`;

export const Image = styled.img.attrs({
    loading: 'lazy',
})`
    img {
        object-fit: cover;

        width: 180px;
        height: 180px;

        @media (max-width: 768px) {
            display: none;
        }
    }
`;

export const Details = styled.div`
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-right: auto;

    @media (max-width: 768px) {
        width: 90%;
    }
`;

export const Title = styled.h4`
    width: 100%;
    color: ${(props) => props.theme.fonts.family.main};
    text-align: left;
    font-size: ${(props) => props.theme.fonts.size.h4};
    font-weight: 600;
    line-height: 44px;
    letter-spacing: 0em;
`;

export const Description = styled.p`
    max-width: 100%;
    margin-bottom: 0;
    text-align: justify;
    font-size: ${(props) => props.theme.fonts.size.p};
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
`;

export const Tags = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    height: 10%;
`;

export const Tag = styled.small`
    margin-right: 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;

    &::before {
        content: '#';
    }
`;
