import { RedButton, WhiteButton } from '@components/shared';
import { Form } from 'formik';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Select from 'react-select';

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto 72px;
  justify-content: center;
  align-items: flex-start;
  padding: 0 24px;

  @media (min-width: ${(props) => props.theme.layout.maxWidth}) {
    flex-direction: row;
  }
`;

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  max-width: 360px;
  width: 100%;

  @media (min-width: ${(props) => props.theme.layout.maxWidth}) {
    padding: 0 15px;
  }

  .remember__Link {
    font-weight: 600;
  }
`;

export const Heading = styled.h3`
  padding: 0 0 38px 0;
`;

export const GatsbyLink = styled(Link)`
  color: ${(props) => props.theme.colors.black};
  font-weight: 600;
  display: flex;
  align-items: center;
  align-self: flex-start;
  text-decoration: none;
  flex-direction: row-reverse;

  &:hover {
    color: ${(props) => props.theme.colors.primary[500]};
  }

  &:active {
    color: ${(props) => props.theme.colors.primary[100]};
  }

  @media (min-width: ${(props) => props.theme.layout.maxWidth}) {
    margin-bottom: 0;
  }
`;

export const Button = styled(RedButton)`
  margin-top: 24px;
`;

export const Label = styled.label`
  margin-bottom: 12px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.primary[500]};
`;

export const SelectInput = styled(Select)`
  border: 1px solid ${(props) => (props.isInvalid ? '#fc4e24' : '#BDBDC7')};
  padding: 3px;
  width: 100%;
  background: transparent;
  font-family: ${(props) => props.theme.fonts.family.main};
  color: ${(props) => props.theme.colors.black};
  font-weight: 600;
  font-size: 16px;

  &::placeholder {
    color: ${(props) => props.theme.colors.gray![500]};
  }

  .react-select__control {
    border: none;
    font-weight: 600;
    box-shadow: none;

    &:hover, &:focus, &:active {
      border: none;
    }
  }
`;

export const LogoutButton = styled(WhiteButton)`
  margin-top: 24px;
`;

export const Success = styled.p`
  margin-top: 20px;
  text-align: center;
  color: #00c853;
`;

export const Failed = styled.p`
  margin-top: 20px;
  text-align: center;
  color: #ed3103;
`;
