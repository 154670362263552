import { MailerLiteApi } from '@api';
import { CheckboxInput, TextInput } from '@components/shared';
import { Formik, Form } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';

import * as S from './styles';

const initialValues = {
  name: '',
  email: '',
  privacy: false
};

const EbookSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'To pole jest wymagane')
    .required('To pole jest wymagane'),
  email: Yup.string()
    .email('E-mail jest nieprawdłowy')
    .required('To pole jest wymagane'),
  privacy: Yup.boolean()
    .test('is valid', 'To pole jest wymagane', (value) => value === true)
    .required('To pole jest wymagane')
});

interface IFormikValues {
  email: string;
  name: string;
  privacy: boolean;
}

const Ebook: React.FC = (): JSX.Element => {
  const [message, setMessage] = useState<Record<string, string>>({});

  const onSubmit = (
    values: IFormikValues,
    { resetForm }: { resetForm: () => void }
  ) => {
    const { email, name } = values;

    MailerLiteApi.addEbookSubscriber(email, name);
    setMessage({
      status: 'success',
      content: 'E-book został pomyślnie wysłany!'
    });

    resetForm();
  };

  return (
    <S.EbookSection>
      <S.Wrapper>
        <S.Header>Pobierz naszego e-booka</S.Header>
        <S.Description>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc at
          venenatis egestas amet. Vitae aliquam blandit arcu, amet urna,
          tincidunt.
        </S.Description>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={EbookSchema}
        >
          {({ errors, touched, setFieldValue }) => (
            <Form>
              <S.InputsContainer>
                <TextInput
                  name="name"
                  placeholder="Imię"
                  isInvalid={Boolean(errors.name && touched.name)}
                />
                <TextInput
                  name="email"
                  type="email"
                  placeholder="Adres e-mail"
                  isInvalid={Boolean(errors.email && touched.email)}
                />
              </S.InputsContainer>
              <S.StyledRedButton type="submit">Wyślij</S.StyledRedButton>
              <CheckboxInput
                setFieldValue={setFieldValue}
                isInvalid={Boolean(errors.privacy && touched.privacy)}
                name="privacy"
              >
                <label htmlFor="privacy">
                  Twoje dane osobowe zostaną użyte do obsługi twojej wizyty na
                  naszej stronie, zarządzania dostępem do twojego konta i dla
                  innych celów o których mówi nasza polityka prywatności.
                </label>
              </CheckboxInput>
              {message.content && (
                <S.MessageContainer>{message.content}</S.MessageContainer>
              )}
            </Form>
          )}
        </Formik>
      </S.Wrapper>
    </S.EbookSection>
  );
};

export default Ebook;
