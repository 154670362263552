import styled from 'styled-components';

export const TitleSection = styled.section`
  padding: 72px 24px;
  margin: 0 auto;
  width: 100%;
  background-color: ${(props) => props.theme.colors.white};
  text-align: center;
  padding: 48px 0 0;
`;
