import {
  startOfWeek,
  startOfDay,
  addDays,
  startOfMonth,
  endOfMonth,
  endOfWeek,
  addWeeks,
  isAfter
} from 'date-fns';

const monthsNames = [
  'Styczeń',
  'Luty',
  'Marzec',
  'Kwiecień',
  'Maj',
  'Czerwiec',
  'Lipiec',
  'Sierpień',
  'Wrzesień',
  'Październik',
  'Listopad',
  'Grudzień'
];

const monthsNamesWithAddition = [
  'stycznia',
  'lutego',
  'marca',
  'kwietnia',
  'maja',
  'czerwca',
  'lipca',
  'sierpnia',
  'września',
  'października',
  'listopada',
  'grudnia'
];

const weekdaysNames = ['PON', 'WT', 'ŚR', 'CZW', 'PT', 'SOB', 'NIEDZ'];

const takeWeek = (start = new Date()) => {
  const date = startOfWeek(startOfDay(start), { weekStartsOn: 1 });
  const newArr = new Array(7).fill('') as Array<string>;
  const week = [...newArr].map((el, id) => addDays(date, id));
  return week;
};

const takeMonth = (start = new Date()): Array<Array<Date>> => {
  const month: Array<Array<Date>> = [];
  let startingDate = startOfWeek(startOfMonth(start), { weekStartsOn: 1 });
  const weekGen = (newDate: Date) => takeWeek(newDate);
  const endDate = startOfDay(endOfWeek(endOfMonth(start)));

  while (!isAfter(startingDate, endDate)) {
    month.push(weekGen(startingDate));
    startingDate = addWeeks(startingDate, 1);
  }
  return month;
};

export { monthsNames, weekdaysNames, monthsNamesWithAddition, takeMonth };
