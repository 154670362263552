import { Link } from 'gatsby';
import styled from 'styled-components';

const Hero = styled.section`
  margin: 0 auto;
`;

const Header = styled.h2`
  margin: 88px auto;
  position: absolute;
  font-weight: 400;
  width: 100%;
  text-align: center;
  color: ${(props) => props.theme.colors.primary[300]};
`;

const Logos = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: ${(props) => props.theme.layout.maxWidth};
  width: 100%;
  margin: 0 auto;
  margin-bottom: 72px;

  @media (max-width: 768px) {
    display: none;
  }
`;
const SingleLogo = styled.img`
  margin: 0 16px;
`;

const HeroSlider = styled.div`
  max-width: 100%;
  margin: 0 auto;

  .slick-slide {
    position: relative;
  }

  .slick-dots {
    bottom: 183px;

    @media (min-width: ${(props) => props.theme.layout.maxWidth}) {
      bottom: 72px;
    }

    li {
      width: 8px;
      height: 8px;

      button {
        font-size: 0;
        line-height: 0;
        display: inline-block;
        width: 8px;
        border: 1px solid black;
        border-radius: 50%;
        background: transparent;
        height: 8px;
        padding: 0;
        cursor: pointer;
        outline: 0;

        &:before {
          content: '';
        }
      }
    }

    li.slick-active {
      button {
        background: #000;
      }
    }
  }
`;

const SingleSlide = styled.div`
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.colors.black};
  object-position: 28%;

  img {
    margin: 0 auto;
    height: 628px;
    width: 100%;
    object-fit: cover;
    object-position: 28%;
  }
`;

const Bottom = styled.div`
  width: 100%;
  position: absolute;
  display: flex;
  bottom: 52px;
  padding: 0 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: ${(props) => props.theme.layout.maxWidth}) {
    padding: 0 10rem;
    bottom: 84px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  gap: 17px;

  @media (min-width: ${(props) => props.theme.layout.maxWidth}) {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
`;

const Div = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 17px;

  @media (min-width: ${(props) => props.theme.layout.maxWidth}) {
    align-items: center;
  }
`;

const GatsbyLink = styled(Link)`
  color: ${(props) => props.theme.colors.primary[300]};
  font-weight: 600;
  display: flex;
  align-items: center;
  text-decoration: none;
  flex-direction: row-reverse;

  &:hover {
    color: ${(props) => props.theme.colors.primary[500]};

    svg {
      path {
        stroke: ${(props) => props.theme.colors.primary[500]};
      }
    }
  }

  &:active {
    color: ${(props) => props.theme.colors.primary[100]};

    svg {
      path {
        stroke: ${(props) => props.theme.colors.primary[100]};
      }
    }
  }

  @media (min-width: ${(props) => props.theme.layout.maxWidth}) {
    align-self: flex-end;
    margin-bottom: 0;
  }

  svg {
    margin-left: 8px;
    stroke: ${(props) => props.theme.colors.primary[300]};
    transform: rotate(180deg);
  }
`;

export {
  Hero,
  Header,
  Logos,
  SingleLogo,
  HeroSlider,
  SingleSlide,
  Bottom,
  GatsbyLink,
  Container,
  Div
};
