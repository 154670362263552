import React from 'react';

import * as S from './styles';

const ForgotPasswordTitle = (): JSX.Element => (
  <S.TitleSection>
    <h1>Przypomnij hasło</h1>
  </S.TitleSection>
);

export default ForgotPasswordTitle;
