import React, { useState, useEffect } from 'react';

import { monthsNamesWithAddition } from '../utils';
import * as S from './styles';

const getDateName = (chosenDate: Date) =>
  `${chosenDate.getDate()} ${
    monthsNamesWithAddition[chosenDate.getMonth()]
  } ${chosenDate.getFullYear()}`;

const deadlines = [
  {
    hour: '12:00',
    available: false
  },
  {
    hour: '14:00',
    available: true
  },
  {
    hour: '16:00',
    available: true
  },
  {
    hour: '18:00',
    available: true
  }
];

interface AppointmentBoxProps {
  chosenDate: Date;
  setDateTime: (field: string, value: string | Date) => void;
}

const AppointmentBox: React.FC<AppointmentBoxProps> = ({
  chosenDate,
  setDateTime
}): JSX.Element => {
  const [dateName, setDateName] = useState(getDateName(chosenDate));
  const [chosenHour, setChosenHour] = useState('');

  const setNewDateTime = (hour: string) => {
    const [newHour, newMinutes] = hour.split(':');
    setDateTime(
      'callDate',
      new Date(chosenDate.setHours(Number(newHour), Number(newMinutes), 0))
    );
  };

  useEffect(() => {
    setDateName(getDateName(chosenDate));
    setChosenHour('');
  }, [chosenDate]);

  return (
    <S.Container>
      <strong>{dateName}</strong>
      <S.ButtonsContainer>
        {deadlines.map(({ hour, available }) => (
          <S.StyledButton
            type="button"
            onClick={() => {
              setChosenHour(hour);
              setNewDateTime(hour);
            }}
            disabled={!available}
            isActive={hour === chosenHour}
            key={hour}
          >
            {hour}
          </S.StyledButton>
        ))}
      </S.ButtonsContainer>
    </S.Container>
  );
};

export default AppointmentBox;
