import { Link } from 'gatsby';
import styled from 'styled-components';

const Section = styled.section`
  border-bottom: 1px solid ${(props) => props.theme.colors.black};
  width: 100%;
  padding: 72px 0;
  margin: 0 auto;
`;

const ArticlesList = styled.div`
  max-width: ${(props) => props.theme.layout.maxWidth};
  width: 100%;
  margin: 0 auto;

  .dataContainer {
    padding-inline: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;

    @media (min-width: 768px) {
      display: grid;
      grid-template-columns: 1fr;
      justify-items: center;
      align-items: flex-start;
      column-gap: 24px;
      row-gap: 32px;
      padding-inline: 0;
    }

    @media (min-width: ${(props) => props.theme.layout.maxWidth}) {
      grid-template-columns: 1fr;
    }
  }

  .pagination {
    margin-top: 32px;
    display: flex;
    justify-content: center;
    gap: 16px;

    .pagesContainer {
      display: flex;
      justify-content: center;
      gap: 8px;
    }

    .next,
    .previous,
    .page {
      border: none;
      outline: none;
      background-color: unset;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .page {
      font-weight: 600;
      font-size: 16px;

      &.active {
        color: ${(props) => props.theme.colors.primary[300]};
      }

      &:hover {
        color: ${(props) => props.theme.colors.primary[500]};
      }

      &:active {
        color: ${(props) => props.theme.colors.primary[100]};
      }
    }

    .next {
      transform: rotate(180deg);
    }

    .next,
    .previous {
      padding: 4px 8px;
      path {
        &:hover {
          stroke: ${(props) => props.theme.colors.primary[500]};
        }

        &:active {
          stroke: ${(props) => props.theme.colors.primary[100]};
        }
      }

      &:disabled {
        path {
          &,
          &:hover,
          &:active {
            stroke: ${(props) => props.theme.colors.gray[500]};
          }
        }
      }
    }
  }
`;

const SubcategoriesWrapper = styled.div`
  max-width: 900px;
  margin: 0 auto 48px auto;
  display: flex;
  flex-direction: row;
  padding: 0 24px;
  justify-content: space-evenly;

  @media (min-width: ${(props) => props.theme.layout.maxWidth}) {
    padding: 0;
    justify-content: flex-start;
  }
`;

const GatsbyLink = styled(Link)`
  color: ${(props) => props.theme.colors.black};
  font-weight: 600;
  text-decoration: none;

  &:hover {
    color: ${(props) => props.theme.colors.primary[500]};
  }

  &.active {
    color: ${(props) => props.theme.colors.primary[100]};
    font-weight: 700;
  }

  @media (min-width: ${(props) => props.theme.layout.maxWidth}) {
    &:first-child {
      padding: 0;
    }

    padding: 0 0 0 24px;
    justify-content: flex-start;
  }
`;

export { Section, ArticlesList, SubcategoriesWrapper, GatsbyLink };
