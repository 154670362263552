import { Form } from 'formik';
import styled from 'styled-components';

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  margin: 48px auto 0px auto;
`;

export const CheckboxContainer = styled.div`
  margin: 32px auto;
  text-align: left;
`;

export const ButtonContainer = styled.div`
  margin-top: 32px;
`;

export const MessageContainer = styled.h4`
  color: #00c853;
  margin-top: 32px;
`;
