import { Link } from 'gatsby';
import styled from 'styled-components';

interface SectionProps {
  bg: string;
}

const Section = styled.section<SectionProps>`
  border-bottom: 1px solid ${(props) => props.theme.colors.black};
  background: ${(props) => props.bg};
`;

const Products = styled.section`
  padding: 72px 24px;
  margin: 0 auto;
  max-width: 1128px;
  width: 100%;

  @media (min-width: 1128px) {
    padding: 72px 0;
  }
`;

const Information = styled.div`
  margin-bottom: 64px;

  @media (min-width: 1128px) {
    display: grid;
    grid-template-columns: 360px 1fr;
    grid-template-rows: 116px 74px;
    column-gap: 24px;
  }
`;

const Header = styled.h2`
  font-weight: 400;
  margin-bottom: 64px;
`;

const Description = styled.div`
  margin-bottom: 64px;

  @media (min-width: 1128px) {
    align-self: end;
    grid-row: 1 / span 2;
    grid-column: 2 / 3;
    margin-bottom: 0;
  }

  p {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const GatsbyLink = styled(Link)`
  color: ${(props) => props.theme.colors.primary[300]};
  font-weight: 600;
  display: flex;
  align-items: center;
  margin-bottom: 64px;
  text-decoration: none;

  &:hover {
    color: ${(props) => props.theme.colors.primary[500]};

    svg {
      path {
        stroke: ${(props) => props.theme.colors.primary[500]};
      }
    }
  }

  &:active {
    color: ${(props) => props.theme.colors.primary[100]};

    svg {
      path {
        stroke: ${(props) => props.theme.colors.primary[100]};
      }
    }
  }

  @media (min-width: 1128px) {
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-self: end;
    margin-bottom: 0;
  }

  svg {
    margin-right: 8px;
    stroke: ${(props) => props.theme.colors.primary[300]};

    @media (min-width: 1128px) {
      transform: rotate(180deg);
      margin-left: 8px;
    }
  }
`;

export { Products, Header, Description, GatsbyLink, Information, Section };
