/* eslint-disable consistent-return */

/* eslint-disable @typescript-eslint/no-unsafe-call */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */

/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { WPApi } from '@api';
import { AuthCtx, useActions } from '@components/contexted';
import { CheckboxInput, TextInput } from '@components/shared';
import { LoginPageText } from '@components/standard';
import { Formik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';

import * as S from './styles';

const formSchema = Yup.object().shape({
  email: Yup.string()
    .required('To pole jest wymagane')
    .email('Wpisz prawidłowy email'),
  password: Yup.string()
    .required('To pole jest wymagane.')
    .min(6, 'Hasło musi zawierać minimum 6 znaków')
  // .matches(
  //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])/, // eslint-disable-line
  //   'Hasło musi zawierać małą i wielką literę, cyfrę oraz znak specjalny'
  // )
});

const initialValues = {
  email: '',
  password: '',
  remember: false
};

const LoginForm = (): JSX.Element => {
  const [response, setResponse] = useState({
    message: '',
    success: false
  });
  const { logIn } = useActions(AuthCtx, ['logIn']);

  return (
    <S.FormWrapper>
      <Formik
        initialValues={initialValues}
        validationSchema={formSchema}
        onSubmit={
          (values, { resetForm }) =>
            WPApi.loginUser(values)
              .then((res) => {
                logIn(res.data);
                const { token, id } = res.data;

                if (token) {
                  return WPApi.getUserInfo(token, id).then(() => {
                    // Cookies.set('token', token, { expires: 7 });
                    // setUserDetails(data);
                    setResponse({
                      message: 'Pomyślnie zalogowano',
                      success: true
                    });
                    resetForm();
                  });
                }
              })
              .catch(() => {
                setResponse({
                  message: 'Nieprawidłowe dane. Prosimy spróbować ponownie.',
                  success: false
                });
              })
          // eslint-disable-next-line react/jsx-curly-newline
        }
      >
        {({ errors, touched, setFieldValue }) => (
          <S.StyledForm>
            <S.Heading>Logowanie</S.Heading>
            <TextInput
              name="email"
              type="email"
              isInvalid={Boolean(errors.email && touched.email)}
              placeholder="Adres e-mail"
            />
            <TextInput
              name="password"
              type="password"
              isInvalid={Boolean(errors.password && touched.password)}
              placeholder="Hasło"
            />
            <CheckboxInput setFieldValue={setFieldValue} name="remember">
              <label htmlFor="remember" className="remember__Link">
                Zapamiętaj mnie
              </label>
            </CheckboxInput>
            <S.Button type="submit">Zaloguj się</S.Button>

            {response.message && response.success ? (
              <S.Success>{response.message}</S.Success>
            ) : (
              <S.Failed>{response.message}</S.Failed>
            )}

            <S.GatsbyLink to="/przypomnij-haslo">
              Nie pamiętasz hasła?
            </S.GatsbyLink>
          </S.StyledForm>
        )}
      </Formik>
      <LoginPageText />
    </S.FormWrapper>
  );
};

export default LoginForm;
