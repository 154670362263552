import React from 'react';

import MakeAppointmentForm from '../MakeAppointmentForm';
import FormikForm from './FormikForm';
import * as S from './styles';

const ContactForm = (): JSX.Element => (
  <S.Section>
    <S.Wrapper>
      <S.PhoneNumberContainer>
        <S.PhoneNumber>+48 515 132 179</S.PhoneNumber>
        <p>Poniedziałek - Piątek</p>
        <p>9:00 - 17:00</p>
      </S.PhoneNumberContainer>
      <MakeAppointmentForm />
      <FormikForm />
    </S.Wrapper>
  </S.Section>
);

export default ContactForm;
