import { withHtml } from '@components/logic';
import { Link } from 'gatsby';
import styled from 'styled-components';

const Post = styled.article`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  width: 80%;
  border-bottom: 1px solid ${(props) => props.theme.colors.black};
`;
interface HeaderProps {
  bgImage: string;
}

const PostHeader = styled.div<HeaderProps>`
  padding: 0;
  max-height: 400px;
  height: 100vh;
  width: 100%;
  top: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  position: relative;

  &:before {
    position: absolute;
    content: '';
    top: 0;
    left: -10vw;
    height: 100%;
    width: 100vw;
    background: url(${(props) => props.bgImage}) no-repeat bottom;
    background-size: cover;
    z-index: -1;
    filter: brightness(0.65);
  }
`;

const PostContent = styled.div`
  margin: 72px 0;

  @media (min-width: ${(props) => props.theme.layout.maxWidth}) {
    margin: 72px 0 0;
  }
`;

const Title = styled.h1`
  margin: 72px 0 18px;
  color: ${(props) => props.theme.colors.white};
`;

const Author = styled.h3`
  color: ${(props) => props.theme.colors.white};
`;

const Tags = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  height: 10%;
`;

const Tag = styled.small`
  margin-right: 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;

  &::before {
    content: '#';
  }
`;

const Text = styled(withHtml('div'))`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  img {
    max-width: 660px;
    max-height: 350px;
    object-fit: cover;
  }

  hr {
    margin: 10px 0;
  }

  p,
  li {
    color: ${(props) => props.theme.colors.gray[700]};
    line-height: 1.5em;
    margin: 8px 0;
    text-align: left;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 700;
    margin: 16px 0;
    color: ${(props) => props.theme.colors.gray[900]};
  }

  ul,
  ol {
    margin-top: 8px;
  }

  li {
    list-style-type: circle;
    list-style-position: outside;
    margin-left: 48px;
  }

  a {
    text-decoration: underline;
    font-weight: 600;
    color: ${(props) => props.theme.colors.primary[900]};
  }
`;

const Navigation = styled.div`
  display: none;

  @media (min-width: ${(props) => props.theme.layout.maxWidth}) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 48px 0 72px 0;
  }
`;

interface IconProps {
  rotate: boolean;
}

const GatsbyLink = styled(Link)<IconProps>`
  color: ${(props) => props.theme.colors.primary[300]};
  font-weight: 600;
  display: flex;
  text-decoration: none;
  align-items: center;

  &:hover {
    color: ${(props) => props.theme.colors.primary[500]};

    svg {
      path {
        stroke: ${(props) => props.theme.colors.primary[500]};
      }
    }
  }

  &:active {
    color: ${(props) => props.theme.colors.primary[100]};

    svg {
      path {
        stroke: ${(props) => props.theme.colors.primary[100]};
      }
    }
  }

  svg {
    margin: 0 8px;
    stroke: ${(props) => props.theme.colors.primary[300]};
    transform: ${(props) => (props.rotate ? 'rotate(0deg)' : 'rotate(180deg)')};
  }
`;

export {
  Post,
  PostHeader,
  Title,
  Author,
  Tags,
  Tag,
  Text,
  PostContent,
  Navigation,
  GatsbyLink
};
