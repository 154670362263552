/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { IPost } from '@standardTypes';
import React from 'react';

import * as S from './styles';

interface PostProps {
  data: IPost;
}

const Post: React.FC<PostProps> = ({ data }): JSX.Element => {
  const { title, slug } = data;
  const { metaDesc } = data.seo;
  const { sourceUrl } = data.featuredImage.node;

  return (
    <S.GatsbyLink to={`${slug}`}>
      <S.Post>
        <S.Image src={sourceUrl} />
        <S.Title>{title}</S.Title>
        <S.Description>{metaDesc}</S.Description>
      </S.Post>
    </S.GatsbyLink>
  );
};

export default Post;
