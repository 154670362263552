import styled from 'styled-components';

const Section = styled.section`
  padding: 72px 24px;
  border-bottom: 1px solid ${(props) => props.theme.colors.black};

  @media (min-width: ${(props) => props.theme.layout.maxWidth}) {
  }
`;

const Title = styled.h2`
  text-align: center;
  margin-bottom: 64px;
  font-weight: 400;

  @media (min-width: ${(props) => props.theme.layout.maxWidth}) {
    max-width: 550px;
    margin-inline: auto;
  }
`;

const Text = styled.p`
  text-align: center;

  @media (min-width: ${(props) => props.theme.layout.maxWidth}) {
    max-width: 550px;
    margin-inline: auto;
  }
`;

const Links = styled.p`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 64px 0 0;

  @media (min-width: ${(props) => props.theme.layout.maxWidth}) {
    flex-direction: row;
  }
`;

const Link = styled.a`
  position: relative;
  padding: 12px 0;

  @media (min-width: ${(props) => props.theme.layout.maxWidth}) {
    padding: 0 12px;
  }
`;

const LogoImage = styled.img`
  position: relative;
  top: 0;
  left: 0;
`;

export { Section, Title, Text, Links, Link, LogoImage };
