import {
  TextInput,
  CollapsibleGrayButton,
  LoadingButton
} from '@components/shared';
import { Formik, Form } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';

import AppointmentBox from './AppointmentBox';
import Calendar from './Calendar';
import SuccessSVG from './icons/success.svg';
import * as S from './styles';

const checkDateValidity = (chosenDate: string | undefined) => {
  if (chosenDate === undefined || chosenDate.length === 0) return false;
  const date = new Date(chosenDate);
  return date.getHours() !== 0;
};

const formSchema = Yup.object().shape({
  fullname: Yup.string()
    .required('To pole jest wymagane')
    .min(3, 'To pole jest wymagane'),
  email: Yup.string()
    .required('To pole jest wymagane')
    .email('Wpisz prawidłowy adres e-mail'),
  callDate: Yup.string().test({
    name: 'validDate',
    message: 'Wybierz prawidłowy termin',
    test: (value) => checkDateValidity(value)
  })
});

const checkValuesValidity = (arr: Array<Record<string, any>>) => {
  const [errors, touched] = arr;
  const keys = Object.keys(touched);
  if (keys.length === 0) return false;
  const errorKeys = Object.keys(errors);
  const result = keys.some((key) => errorKeys.includes(key));
  return result;
};

const initialValues = {
  fullname: '',
  email: '',
  callDate: ''
};

const MakeAppointmentForm = (): JSX.Element => {
  const [chosenDate, setChosenDate] = useState<Date>(new Date());
  const [isFormFinished, setIsFormFinished] = useState(false);

  return (
    <>
      <CollapsibleGrayButton title="Umów się na rozmowę">
        <S.AppointmentContent>
          {isFormFinished ? (
            <S.SuccessContainer>
              <img src={SuccessSVG} alt="success-icon" />
              <S.SuccessInfo>Twoja rozmowa została umówiona</S.SuccessInfo>
              <p>Dostaniesz potwierdzenie na adres e-mail</p>
            </S.SuccessContainer>
          ) : (
            <>
              <Formik
                initialValues={initialValues}
                validationSchema={formSchema}
                onSubmit={(values) => {
                  alert(JSON.stringify(values));
                  // request do google calendar
                  // sprawdz czy data nie jest już zajęta
                  // setIsFormFinished(true);
                  setIsFormFinished(true);
                }}
              >
                {({ errors, touched, setFieldValue }) => (
                  <Form>
                    {checkValuesValidity([errors, touched]) && (
                      <S.ErrorInfo>Proszę uzupełnić wymagane pola</S.ErrorInfo>
                    )}
                    <S.FormDescription>
                      If you have shopped with us before, please enter your
                      details below. If you are a new customer, please proceed
                      to the Billing section.
                    </S.FormDescription>
                    <S.InputsContainer>
                      <TextInput
                        name="fullname"
                        isInvalid={Boolean(errors.fullname && touched.fullname)}
                        placeholder="Imię i nazwisko"
                      />
                      <TextInput
                        name="email"
                        isInvalid={Boolean(errors.email && touched.email)}
                        placeholder="Adres-email"
                      />
                    </S.InputsContainer>
                    <S.CalendarContainer>
                      <Calendar
                        chosenDate={chosenDate}
                        setChosenDate={setChosenDate}
                        setNewDate={setFieldValue}
                      />
                      <AppointmentBox
                        chosenDate={chosenDate}
                        setDateTime={setFieldValue}
                      />
                    </S.CalendarContainer>
                    {errors.callDate && touched.callDate && (
                      <S.ErrorMessage>Wybierz prawidłowy termin</S.ErrorMessage>
                    )}
                    <LoadingButton
                      type="submit"
                      text="Potwierdź rozmowę"
                      color="red"
                      isBusy={false}
                    />
                  </Form>
                )}
              </Formik>
            </>
          )}
        </S.AppointmentContent>
      </CollapsibleGrayButton>
    </>
  );
};

export default MakeAppointmentForm;
