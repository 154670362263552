import { withHtml } from '@components/logic';
import { Link } from 'gatsby';
import styled from 'styled-components';

const maxCardWidthMobile = '312px';
const maxCardWidthDesktop = '900px';

const Article = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 24px;
  align-items: center;
  max-width: ${maxCardWidthMobile};
  width: 100%;

  @media (min-width: ${(props) => props.theme.layout.maxWidth}) {
    grid-template-columns: 360px 1fr;
    max-width: ${maxCardWidthDesktop};
  }
`;

const Image = styled.img`
  width: 100%;
  max-width: ${maxCardWidthMobile};
  border: 1px solid ${(props) => props.theme.colors.black};

  @media (min-width: ${(props) => props.theme.layout.maxWidth}) {
    max-width: ${maxCardWidthDesktop};
  }
`;

const Title = styled.h4`
  margin-top: 24px;
  color: ${(props) => props.theme.colors.black};
`;

const Description = styled(withHtml('div'))`
  margin-top: 16px;
  width: 100%;
  color: ${(props) => props.theme.colors.black};
`;

const GatsbyLink = styled(Link)`
  text-decoration: none;

  &:hover {
    ${Image} {
      border: 1px solid ${(props) => props.theme.colors.primary[300]};
    }

    ${Title} {
      color: ${(props) => props.theme.colors.primary[300]};
    }
  }
`;

export { Article, Image, Title, Description, GatsbyLink };
