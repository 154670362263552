import React from 'react';
import Slider from 'react-slick';
import StarRating from 'react-star-ratings';

import * as S from './styles';

const settings = {
  arrows: false,
  infinite: true,
  slidesToShow: 3,
  autoplay: true,
  autoplaySpeed: 5000,
  speed: 2000,
  slidesToScroll: 1,
  dots: true,
  useTransform: false,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        autoplay: true,
        infinite: true,
        autoplaySpeed: 3000,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};
const reviews = [
  {
    rating: 5,
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc at venenatis egestas amet. Vitae aliquam blandit arcu, amet urna, tincidunt',
    author: 'Jan Kowalski'
  },
  {
    rating: 5,
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc at venenatis egestas amet. Vitae aliquam blandit arcu, amet urna, tincidunt',
    author: 'Jan Kowalski'
  },
  {
    rating: 4,
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc at venenatis egestas amet. Vitae aliquam blandit arcu, amet urna, tincidunt',
    author: 'Jan Kowalski'
  },
  {
    rating: 5,
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc at venenatis egestas amet. Vitae aliquam blandit arcu, amet urna, tincidunt',
    author: 'Jan Kowalski'
  }
];
const CompaniesReviews: React.FC = (): JSX.Element => (
  <S.Section>
    <S.Reviews>
      <S.Header>Opinie naszych klientów</S.Header>
      <S.RatingSlider>
        <Slider {...settings}>
          {reviews.map((el) => (
            <S.SingleReview>
              <StarRating
                rating={el.rating}
                numberOfStars={5}
                starRatedColor="#FFD700"
                starDimension="18px"
              />
              <S.ReviewContent>{el.content}</S.ReviewContent>
              <S.Author>{el.author}</S.Author>
            </S.SingleReview>
          ))}
        </Slider>
      </S.RatingSlider>
    </S.Reviews>
  </S.Section>
);
export default CompaniesReviews;
