import React from 'react';

import * as S from './styles';

const data = [
  {
    text: 'śledzić swoje zamówienie,'
  },
  {
    text: 'zadawać pytania o kupione produkty,'
  },
  {
    text: 'dzielić się opiniami o łąkach,'
  },
  {
    text: 'przechowywać historię zakupów.'
  }
];

const LoginPageText = (): JSX.Element => (
  <S.Form>
    <S.Heading>Nie masz konta?</S.Heading>
    <div>
      Zarejestruj się, żeby:
      <ul>
        {data.map(({ text }) => (
          <li key={text}>{text}</li>
        ))}
      </ul>
      Zostań z nami.
    </div>
    <S.GatsbyLink to="/rejestracja" type="submit">
      Zarejestruj się
    </S.GatsbyLink>
  </S.Form>
);

export default LoginPageText;
