import { Link } from 'gatsby';
import styled from 'styled-components';

const maxCardWidthMobile = '312px';
const maxCardWidthDesktop = '560px';

const Post = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${maxCardWidthMobile};
  width: 100%;

  @media (min-width: 1128px) {
    max-width: ${maxCardWidthDesktop};
  }
`;

const Image = styled.img`
  width: 100%;
  max-width: ${maxCardWidthMobile};
  border: 1px solid ${(props) => props.theme.colors.black};

  @media (min-width: 1128px) {
    max-width: ${maxCardWidthDesktop};
  }
`;

const Title = styled.h4`
  margin-top: 24px;
  color: ${(props) => props.theme.colors.black};
`;

const Description = styled.p`
  margin-top: 16px;
  width: 100%;
  color: ${(props) => props.theme.colors.black};
`;

const GatsbyLink = styled(Link)`
  text-decoration: none;

  &:hover {
    ${Image} {
      border: 1px solid ${(props) => props.theme.colors.primary[300]};
    }

    ${Title} {
      color: ${(props) => props.theme.colors.primary[300]};
    }
  }
`;

export { Post, Image, Title, Description, GatsbyLink };
