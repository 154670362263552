import React from 'react';

import ShowroomImage from './showroom.png';
import * as S from './styles';

const showrooms = [
  {
    id: 1,
    img: ShowroomImage,
    city: 'Warszawa',
    description: [
      'Inspace Showroom, Hala Koszyki',
      'Antresola, piętro 1, Ulica Koszykowa',
      '63 00-667 Warszawa'
    ],
    contact: 'Piotr: 730 888 748'
  },
  {
    id: 2,
    img: ShowroomImage,
    city: 'Warszawa',
    description: [
      'Inspace Showroom, Hala Koszyki',
      'Antresola, piętro 1, Ulica Koszykowa',
      '63 00-667 Warszawa'
    ],
    contact: 'Piotr: 730 888 748'
  }
];

const ContactForm = (): JSX.Element => (
  <S.Section>
    <S.Wrapper>
      <S.SectionDescriptionContainer>
        <S.SectionTitle>Nasze showroomy</S.SectionTitle>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc at
          venenatis egestas amet. Vitae aliquam blandit arcu, amet urna,
          tincidunt.
        </p>
      </S.SectionDescriptionContainer>
      <S.ShowroomsWrapper>
        {showrooms.map(({ id, img, city, description, contact }) => (
          <S.SingleShowroom key={id}>
            <img src={img} alt="place_image" />
            <h4>{city}</h4>
            <div>
              {description.map((el) => (
                <p key={el}>{el}</p>
              ))}
            </div>
            <p>{contact}</p>
          </S.SingleShowroom>
        ))}
      </S.ShowroomsWrapper>
    </S.Wrapper>
  </S.Section>
);

export default ContactForm;
