import React from 'react';
import Slider from 'react-slick';

import ArrowSVG from '../../icons/ArrowSVG';
import coverImage from './images/HeroBG.png';
import * as S from './styles';

const data = [
  {
    image: coverImage,
    content: {
      title: 'Twoje nowe, spersonalizowane miejsce pracy.',
      text: 'Biurko elektryczne Tystnad'
    }
  },
  {
    image: coverImage,
    content: {
      title: 'Twoje nowe, spersonalizowane miejsce pracy.',
      text: 'Biurko elektryczne Tystnad'
    }
  },
  {
    image: coverImage,
    content: {
      title: 'Twoje nowe, spersonalizowane miejsce pracy.',
      text: 'Biurko elektryczne Tystnad'
    }
  },
  {
    image: coverImage,
    content: {
      title: 'Twoje nowe, spersonalizowane miejsce pracy.',
      text: 'Biurko elektryczne Tystnad'
    }
  },
  {
    image: coverImage,
    content: {
      title: 'Twoje nowe, spersonalizowane miejsce pracy.',
      text: 'Biurko elektryczne Tystnad'
    }
  }
];

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        autoplay: true,
        autoplaySpeed: 3000,
        speed: 1000
      }
    }
  ]
};

const Hero: React.FC = (): JSX.Element => (
  <S.Hero>
    <S.HeroSlider>
      <Slider {...settings}>
        {data.map(({ image, content: { title, text } }) => (
          <S.SingleSlide key={title}>
            <S.Header>{title}</S.Header>
            <img src={image} alt={title} />
            <S.Bottom>
              <S.Container>
                <S.GatsbyLink to="/nasza-historia">
                  <ArrowSVG />
                  Poznaj naszą historię
                </S.GatsbyLink>
                <S.Div>
                  {text}
                  <S.GatsbyLink to="/wiedza">
                    <ArrowSVG />
                    Dowiedz się więcej
                  </S.GatsbyLink>
                </S.Div>
              </S.Container>
            </S.Bottom>
          </S.SingleSlide>
        ))}
      </Slider>
    </S.HeroSlider>
  </S.Hero>
);

export default Hero;
