import { RedButton } from '@components/shared';
import { Link } from 'gatsby';

import styled, { css } from 'styled-components';

const mixin = css`
  width: 100%;
  padding: 12px 32px;
  font-family: ${(props) => props.theme.fonts.family.main};
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 360px;
  width: 100%;
  padding: 38px 0;

  @media (min-width: ${(props) => props.theme.layout.maxWidth}) {
    padding: 0 15px;
  }

  ul {
    padding: 30px;
  }
`;

export const Heading = styled.h3`
  padding: 0 0 38px 0;
`;

export const GatsbyLink = styled(Link)`
  color: ${(props) => props.theme.colors.white};
  background: ${(props) => props.theme.colors.primary[300]};
  border: none;
  text-decoration: none;
  text-align: center;
  margin-top: 32px;
  ${mixin}

  &:hover {
    background: ${(props) => props.theme.colors.primary[500]};
  }

  &:active {
    background: ${(props) => props.theme.colors.primary[100]};
  }
`;