import styled from 'styled-components';

export const Section = styled.section`
  width: 100%;
  background-color: ${(props) => props.theme.colors.white};
  border-bottom: 1px solid ${(props) => props.theme.colors.black};
  text-align: center;
`;

export const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 72px 24px;

  @media (min-width: 1128px) {
    width: 551px;
    padding: 72px 0px;
  }
`;

export const PhoneNumberContainer = styled.div`
  border: 1px solid ${(props) => props.theme.colors.gray![500]};
  padding: 32px;
  margin-bottom: 16px;
`;

export const PhoneNumber = styled.h3`
  margin-bottom: 32px;
`;

export const CollapsibleButton = styled.button`
  margin-top: 16px;
  width: 100%;
  padding: 12px 32px;
  background: ${(props) => props.theme.colors.gray![100]};
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const ArrowImage = styled.img`
  margin-left: 13px;
`;
