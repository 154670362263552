import styled from 'styled-components';

const Section = styled.section`
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.colors.black};
  background-color: ${(props) => props.theme.colors.gray[300]};
`;

const Worth = styled.div`
  padding: 72px 24px;
  margin: 0 auto;
  max-width: 1128px;
  width: 100%;

  @media (min-width: 1128px) {
    padding: 72px 0;
    background-color: ${(props) => props.theme.colors.gray[300]};
  }
`;

const Header = styled.h2`
  margin-bottom: 64px;
`;

const Cards = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 32px;
  column-gap: 24px;

  @media (min-width: 768px) {
    flex-wrap: wrap;
    flex-direction: row;
  }

  @media (min-width: 1128px) {
    display: grid;
    grid-template-columns: repeat(3, 3fr);
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 360px;
  width: 100%;
`;

const CardHeader = styled.h4`
  margin-top: 24px;
  font-weight: 600;
`;

const CardText = styled.p`
  margin-top: 16px;
`;

const CardImage = styled.img`
  max-width: 360px;
  max-height: 240px;
  width: 100%;
  height: 100%;
`;

export { Worth, Header, Card, CardHeader, CardImage, CardText, Cards, Section };
