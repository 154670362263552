/* eslint-disable react/no-array-index-key */
import React from 'react';

import DeskPlaceholder from '../../images/DeskPlaceholder.png';
import * as S from './styles';

const placeholder = [1, 2, 3];

const AboutGallery = (): JSX.Element => (
  <S.Section>
    <S.Title>Nasze wartości</S.Title>
    <S.Gallery>
      {placeholder.map((_, i) => (
        <S.Card key={i}>
          <S.CardImage src={DeskPlaceholder} />
          <S.CardTitle>Lorem ipsum dolor sit amet</S.CardTitle>
          <S.CardText>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam,
            purus sit
          </S.CardText>
        </S.Card>
      ))}
    </S.Gallery>
  </S.Section>
);

export default AboutGallery;
