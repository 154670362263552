import React from 'react';

import AppStore from '../../images/AppStore.svg';
import GooglePlay from '../../images/GooglePlay.svg';
import * as S from './styles';

const AboutHero = (): JSX.Element => (
  <S.Section>
    <S.Title>Pobierz aplikację</S.Title>
    <S.Text>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc at venenatis
      egestas amet. Vitae aliquam blandit arcu, amet urna, tincidunt.
    </S.Text>
    <S.Links>
      <S.Link
        href="https://apps.apple.com/dk/app/desk-control/id1203254365"
        target="_blank"
      >
        <S.LogoImage src={AppStore} />
      </S.Link>
      <S.Link
        href="https://play.google.com/store/apps/details?id=com.linak.deskcontrol"
        target="_blank"
      >
        <S.LogoImage src={GooglePlay} />
      </S.Link>
    </S.Links>
  </S.Section>
);

export default AboutHero;
