const findInString = (str: string, val: string) =>
  str.toLowerCase().includes(val.toLowerCase());

const truncateString = (str: string, num: number) => {
  if (str.length <= num) {
    return str;
  }
  return `${str.slice(0, num)}...`;
};

export { findInString, truncateString };
