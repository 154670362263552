import { Link } from 'gatsby';
import styled from 'styled-components';

const FeaturedText = styled.section`
  position: relative;
  border-bottom: 1px solid ${(props) => props.theme.colors.black};
  display: flex;
  flex-direction: column;

  @media (min-width: 1128px) {
    position: relative;
    height: 100%;
    padding: 0;
    margin: 0;
    flex-direction: row;
  }
`;

const FeaturedImage = styled.img`
  position: relative;
  top: 0;
  left: 0;
`;

const Information = styled.div`
  margin: 72px 24px 0;
  align-items: flex-start;
  display: flex;
  flex-direction: column;

  @media (min-width: 1128px) {
    padding: 0 8rem;
    margin: 0;
    justify-content: center;
  }
`;

const Header = styled.h2`
  font-weight: 400;
  margin-bottom: 64px;
`;

const Description = styled.div`
  margin-bottom: 64px;

  @media (min-width: 1128px) {
    align-self: end;
    grid-row: 1 / span 2;
    grid-column: 2 / 3;
    margin-bottom: 0;
  }

  p {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export { FeaturedImage, FeaturedText, Header, Description, Information };
