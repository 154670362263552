/* eslint-disable react/no-array-index-key */
import React from 'react';
import Slider from 'react-slick';

import PlaceholderSVG from './images/placeholder.svg';
import * as S from './styles';

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        autoplay: true,
        autoplaySpeed: 3000,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

const placeholder = [
  PlaceholderSVG,
  PlaceholderSVG,
  PlaceholderSVG,
  PlaceholderSVG,
  PlaceholderSVG
];
const Trust: React.FC = (): JSX.Element => (
  <S.Section>
    <S.Trust>
      <S.Header>Zaufali nam</S.Header>
      <S.Logos>
        {placeholder.map((el, i) => (
          <S.SingleLogo src={el} key={i} />
        ))}
      </S.Logos>
      <S.LogoSlider>
        <Slider {...settings}>
          {placeholder.map((el, i) => (
            <S.SingleSlide key={i}>
              <img src={el} alt="" />
            </S.SingleSlide>
          ))}
        </Slider>
      </S.LogoSlider>
    </S.Trust>
  </S.Section>
);

export default Trust;
