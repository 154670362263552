/* eslint-disable react/jsx-curly-newline */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */

/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { WPApi } from '@api';
import { TextInput, RedButton } from '@components/shared';
import { Formik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';

import * as S from './styles';

const formSchema = Yup.object().shape({
  email: Yup.string()
    .required('To pole jest wymagane')
    .email('Wpisz prawidłowy email'),
  password: Yup.string()
    .required('To pole jest wymagane.')
    .min(6, 'Hasło musi zawierać minimum 6 znaków'),
  // .matches(
  //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])/, // eslint-disable-line
  //   'Hasło musi zawierać małą i wielką literę, cyfrę oraz znak specjalny'
  // ),
  passwordConfirmation: Yup.string()
    .required('To pole jest wymagane.')
    .oneOf([Yup.ref('password'), null], 'Podane hasła nie są takie same')
});

const initialValues = {
  email: '',
  password: '',
  passwordConfirmation: ''
};

interface IResetFormProps {
  token: string | null;
}

const ResetPasswordForm: React.FC<IResetFormProps> = (props): JSX.Element => {
  const { token } = props;
  const [response, setResponse] = useState({ message: '', success: false });
  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={formSchema}
        onSubmit={(values, { resetForm }) =>
          WPApi.changeUserPasswordFromRemind(values, token)
            .then((data) => {
              setResponse({ message: data.message, success: true });
              resetForm();
            })
            .catch(() => {
              setResponse({
                message: 'Link wygasł. Wygeneruj nowy.',
                success: false
              });
            })
        }
      >
        {({ errors, touched }) => (
          <S.StyledForm>
            <TextInput
              name="email"
              type="email"
              isInvalid={Boolean(errors.email && touched.email)}
              placeholder="Adres e-mail"
            />
            <TextInput
              name="password"
              type="password"
              isInvalid={Boolean(errors.password && touched.password)}
              placeholder="Hasło"
            />
            <TextInput
              name="passwordConfirmation"
              type="password"
              isInvalid={Boolean(
                errors.passwordConfirmation && touched.passwordConfirmation
              )}
              placeholder="Powtórz hasło"
            />

            <RedButton type="submit">Zresetuj hasło</RedButton>
            {response.message && response.success === true ? (
              <S.Success>{response.message}</S.Success>
            ) : (
              <S.Failed>{response.message}</S.Failed>
            )}
          </S.StyledForm>
        )}
      </Formik>
    </div>
  );
};

export default ResetPasswordForm;
