import { RedButton } from '@components/shared';
import styled from 'styled-components';

export const StyledRedButton = styled(RedButton)`
  margin-bottom: 32px;
`;

export const EbookSection = styled.section`
  padding: 72px 24px;
  margin: 0 auto;
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.colors.black};
  text-align: center;

  @media (min-width: 1128px) {
    padding: 72px 0;
  }
`;

export const MessageContainer = styled.h4`
  color: #00c853;
  margin-top: 32px;
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 750px) {
    flex-direction: row;
    justify-content: space-between;

    div {
      width: 47.5%;
    }
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;

  @media (min-width: 1128px) {
    width: 552px;
  }
`;

export const Header = styled.h2`
  font-size: 42px;
  font-weight: 400;
  margin-bottom: 64px;
`;

export const Description = styled.p`
  margin-bottom: 64px;

  @media (min-width: 1128px) {
    display: none;
  }
`;

export const InputContainer = styled.div`
  position: relative;
`;

interface TextInputProps {
  isInvalid: boolean;
}

export const TextInput = styled.input<TextInputProps>`
  border: 1px solid ${(props) => (props.isInvalid ? '#fc4e24' : '#BDBDC7')};
  padding: 12px 36px 12px 12px;
  width: 100%;

  font-family: ${(props) => props.theme.fonts.family.main};
  color: ${(props) => props.theme.colors.black};
  font-weight: 600;
  font-size: 16px;

  &::placeholder {
    color: ${(props) => props.theme.colors.gray!['500']};
  }
`;

export const InputButton = styled.button`
  border: none;
  background: transparent;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  cursor: pointer;
`;

export const CheckboxContainer = styled.div`
  margin: 33px auto 0 auto;
  display: flex;
  flex-direction: row;
`;

export const CheckboxLabel = styled.label`
  flex-grow: 1;
  text-align: left;
`;
