import styled from 'styled-components';

const maxCardWidthMobile = '312px';
const maxCardWidthDesktop = '360px';

const Section = styled.section`
  padding-inline: 24px;
  padding-block: 72px;

  background-color: ${(props) => props.theme.colors.gray[300]};
  border-bottom: 1px solid ${(props) => props.theme.colors.black};
`;

const Title = styled.h2`
  margin-bottom: 64px;
  max-width: ${(props) => props.theme.layout.maxWidth};
  margin-inline: auto;
  text-align: center;

  @media (min-width: ${(props) => props.theme.layout.maxWidth}) {
    text-align: left;
  }
`;

const Gallery = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  max-width: ${(props) => props.theme.layout.maxWidth};
  margin-inline: auto;

  @media (min-width: ${(props) => props.theme.layout.maxWidth}) {
    flex-direction: row;
    padding-inline: 0;
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${maxCardWidthMobile};
  width: 100%;

  @media (min-width: ${(props) => props.theme.layout.maxWidth}) {
    max-width: ${maxCardWidthDesktop};
  }
`;

const CardImage = styled.img`
  max-width: 100%;
  margin-bottom: 24px;
`;

const CardTitle = styled.h4`
  margin-bottom: 16px;
  font-weight: 600;
`;

const CardText = styled.p``;

export { Section, Card, CardText, CardTitle, CardImage, Gallery, Title };
