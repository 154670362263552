import { Link } from 'gatsby';
import styled from 'styled-components';

const FeaturedText = styled.section`
  position: relative;
  border-bottom: 1px solid ${(props) => props.theme.colors.black};
  display: flex;
  flex-direction: column;

  @media (min-width: ${(props) => props.theme.layout.maxWidth}) {
    position: relative;
    height: 100%;
    padding: 0;
    margin: 0;
    flex-direction: row;
  }
`;

const FeaturedImage = styled.img`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
`;

const Information = styled.div`
  margin: 64px 24px;
  align-items: flex-start;
  display: flex;
  flex-direction: column;

  @media (min-width: ${(props) => props.theme.layout.maxWidth}) {
    padding: 8rem;
    justify-content: center;
  }
`;

const Header = styled.h2`
  font-weight: 400;
  margin-bottom: 64px;
`;

const Description = styled.div`
  margin-bottom: 64px;

  @media (min-width: ${(props) => props.theme.layout.maxWidth}) {
    align-self: end;
    grid-row: 1 / span 2;
    grid-column: 2 / 3;
    margin-bottom: 0;
  }

  p {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const GatsbyLink = styled(Link)`
  color: ${(props) => props.theme.colors.primary[300]};
  font-weight: 600;
  display: flex;
  align-items: center;
  text-decoration: none;
  flex-direction: row;

  &:hover {
    color: ${(props) => props.theme.colors.primary[500]};

    svg {
      path {
        stroke: ${(props) => props.theme.colors.primary[500]};
      }
    }
  }

  &:active {
    color: ${(props) => props.theme.colors.primary[100]};

    svg {
      path {
        stroke: ${(props) => props.theme.colors.primary[100]};
      }
    }
  }

  @media (min-width: ${(props) => props.theme.layout.maxWidth}) {
    margin-top: 64px;
    margin-bottom: 0;
  }

  svg {
    margin-left: 8px;
    stroke: ${(props) => props.theme.colors.primary[300]};
    transform: rotate(180deg);
  }
`;

export {
  FeaturedImage,
  FeaturedText,
  Header,
  Description,
  GatsbyLink,
  Information
};
