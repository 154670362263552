/* eslint-disable import/no-cycle */
import React from 'react';

import ArrowSVG from '../../icons/ArrowSVG';
import ListOfProducts from './ListOfProducts';
import * as S from './styles';

export type CategoryType = {
  name: string;
  path: string;
  image: {
    sourceUrl: string;
  };
};

interface ProductProps {
  categories: CategoryType[];
  background: string;
}

const Products: React.FC<ProductProps> = ({
  categories,
  background
}): JSX.Element => (
  <S.Section bg={background}>
    <S.Products>
      <S.Information>
        <S.Header>Poznaj nasze produkty</S.Header>
        <S.Description>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc at
            venenatis egestas amet. Vitae aliquam blandit arcu, amet urna,
            tincidunt. Pellentesque velit eget diam adipiscing neque, elit arcu.
            Nisl, mi facilisis porta eu, euismod id molestie auctor nulla.
          </p>
          <p>
            Faucibus ut mauris urna libero, nulla. Vel quisque facilisis id
            pretium quam suspendisse elit. Mi consectetur viverra in commodo.
            Quis ac arcu vel nec ultricies morbi fusce nisl.
          </p>
        </S.Description>
        <S.GatsbyLink to="kategoria/produkty">
          <ArrowSVG />
          Zobacz wszystkie produkty
        </S.GatsbyLink>
      </S.Information>
      <ListOfProducts categories={categories} />
    </S.Products>
  </S.Section>
);

export default Products;
