import { Link } from 'gatsby';
import styled from 'styled-components';

const ListOfProducts = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  gap: 24px;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const ProductName = styled.h4`
  align-self: flex-start;
  color: ${(props) => props.theme.colors.black};
`;

const ProductImage = styled.img`
  border: 1px solid ${(props) => props.theme.colors.black};
  max-width: 264px;
  max-height: 264px;
`;

const Product = styled.div`
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  cursor: pointer;

  @media (min-width: 768px) {
    padding: 0;
  }
`;

const GatsbyLink = styled(Link)`
  text-decoration: none;
  width: max-content;
  margin: 0 auto;

  &:hover {
    ${ProductName} {
      color: ${(props) => props.theme.colors.primary[300]};
    }

    ${ProductImage} {
      border: 1px solid ${(props) => props.theme.colors.primary[300]};
    }
  }
`;

export { ListOfProducts, Product, ProductName, ProductImage, GatsbyLink };
