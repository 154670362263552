/* eslint-disable import/no-cycle */
import React from 'react';

import { CategoryType } from '../index';
import * as S from './styles';

interface IListOfProcuts {
  categories: CategoryType[];
}

const ListOfProducts: React.FC<IListOfProcuts> = ({
  categories
}): JSX.Element => (
  <S.ListOfProducts>
    {categories?.map(({ name, path, image }: CategoryType) => (
      <S.GatsbyLink to={`/kategoria/${path}`} key={path}>
        {console.log(path)}
        <S.Product>
          <S.ProductImage src={image.sourceUrl} />
          <S.ProductName>{name}</S.ProductName>
        </S.Product>
      </S.GatsbyLink>
    ))}
  </S.ListOfProducts>
);

export default ListOfProducts;
