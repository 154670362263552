/* eslint-disable */
import { IPostPage } from '@standardTypes';
import React from 'react';

import ArrowSVG from '../../../../Home/icons/ArrowSVG';
import * as S from './styles';

const Post: React.FC<{
  page: IPostPage;
  prevPost: () => any;
  nextPost: () => any;
}> = ({ page, prevPost, nextPost }): JSX.Element => (
  <S.Post>
    <S.PostHeader bgImage={page?.featuredImage?.node?.sourceUrl}>
      <S.Title>{page.title}</S.Title>
      <S.Author>{page.author.node?.name}</S.Author>
    </S.PostHeader>
    <S.PostContent>
      <S.Tags>
        {page.tags.nodes.map((tag) => (
          <S.Tag key={tag.name}>{tag.name}</S.Tag>
        ))}
      </S.Tags>
      <S.Text>{page.content}</S.Text>
    </S.PostContent>
    <S.Navigation>
      <S.GatsbyLink
        to={`/${prevPost.categories.nodes
          .slice(0)
          .reverse()
          .map((category) => category.slug + '/')
          .join('')}${prevPost.slug}`}
        rotate
      >
        <ArrowSVG />
        Poprzedni post
      </S.GatsbyLink>
      <S.GatsbyLink
        to={`/${nextPost.categories.nodes
          .slice(0)
          .reverse()
          .map((category) => category.slug + '/')
          .join('')}${nextPost.slug}`}
      >
        Następny post
        <ArrowSVG />
      </S.GatsbyLink>
    </S.Navigation>
  </S.Post>
);
export default Post;
