import React from 'react';

import BgImage from '../../images/FeaturedTextBgImage.png';
import * as S from './styles';

const AboutFeaturedTextSecond: React.FC = (): JSX.Element => (
  <S.FeaturedText>
    <S.Information>
      <S.Header>
        “Lorem ipsum dolor sit amet, consectetur adipiscing elit”
      </S.Header>
      <S.Description>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc at
          venenatis egestas amet. Vitae aliquam blandit arcu, amet urna,
          tincidunt. Pellentesque velit eget diam adipiscing neque, elit arcu.
          Nisl, mi facilisis porta eu, euismod id molestie auctor nulla.
        </p>
      </S.Description>
    </S.Information>
    <S.FeaturedImage src={BgImage} />
  </S.FeaturedText>
);

export default AboutFeaturedTextSecond;
