import React from 'react';

import DeskPlaceholder from '../../images/DeskPlaceholder.png';
import * as S from './styles';

const placeholder: number[] = [1, 2, 3];

const CompaniesWorth: React.FC = (): JSX.Element => (
  <S.Section>
    <S.Worth>
      <S.Header>Dlaczego warto?</S.Header>
      <S.Cards>
        {placeholder.map((el) => (
          <S.Card key={el}>
            <S.CardImage src={DeskPlaceholder} />
            <S.CardHeader>Lorem ipsum dolor sit amet</S.CardHeader>
            <S.CardText>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit ut
              aliquam, purus sit
            </S.CardText>
          </S.Card>
        ))}
      </S.Cards>
    </S.Worth>
  </S.Section>
);

export default CompaniesWorth;
