import { Link } from 'gatsby';
import styled from 'styled-components';

const Section = styled.section`
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.colors.black};
  background-color: white;
`;

const Uses = styled.div`
  padding: 72px 24px;
  margin: 0 auto;
  max-width: 1128px;
  width: 100%;
  display: flex;
  flex-direction: row;

  @media (min-width: 1128px) {
    padding: 72px 0;
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    width: 90%auto;
  }
`;

const Content = styled.div`
  width: 360px;
  display: flex;
  flex-direction: column;
  margin-inline: auto;

  @media (min-width: 768px) {
    maring-inline: 0;
  }
`;

const UsesSlider = styled.div`
  width: 300px;
  .slick-dots {
    text-align: left;
    li {
      width: 8px;
      height: 8px;

      button {
        font-size: 0;
        line-height: 0;
        display: inline-block;
        width: 8px;
        border: 1px solid black;
        border-radius: 50%;
        background: transparent;
        height: 8px;
        padding: 0;
        cursor: pointer;
        outline: 0;

        &:before {
          content: '';
        }
      }
    }
    li.slick-active {
      button {
        background: #000;
      }
    }
  }
  @media (max-width: 768px) {
    margin-bottom: 32px;
  }
`;
const SliderContent = styled.p`
  /* font-family: HK Grotesk; */
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  width: 265px;
  margin-bottom: 64px;
  min-height: 110px;
  @media (max-width: 768px) {
    margin-bottom: 32px;
  }
`;

const PhotosSingle = styled.div`
  max-width: 400px;
  width: 400px;
  display: flex;
  flex-direction: column;
  margin: 0 12px;
  img {
    width: 430px;
  }
  @media (max-width: 768px) {
    width: 100%;
    margin: 0 auto;
    img {
      width: 100%;
    }
  }
`;

const PhotosTitle = styled.h4`
  width: 100%auto;
  /* font-family: HK Grotesk; */
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  margin-top: 24px;
  @media (max-width: 768px) {
    width: 312px;
  }
`;

const PhotosDesc = styled.p`
  /* font-family: HK Grotesk; */
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  margin-top: 24px;
  @media (max-width: 768px) {
    width: 312px;
  }
`;
const Header = styled.h2`
  margin-bottom: 64px;
  width: 360px;
`;

const GatsbyLink = styled(Link)`
  color: ${(props) => props.theme.colors.primary[300]};
  font-weight: 600;
  display: flex;
  text-decoration: none;

  &:hover {
    color: ${(props) => props.theme.colors.primary[500]};

    svg {
      path {
        stroke: ${(props) => props.theme.colors.primary[500]};
      }
    }
  }

  &:active {
    color: ${(props) => props.theme.colors.primary[100]};

    svg {
      path {
        stroke: ${(props) => props.theme.colors.primary[100]};
      }
    }
  }

  @media (min-width: 1128px) {
    margin-top: 64px;
    margin-bottom: 0;
  }

  svg {
    margin-left: 8px;
    stroke: ${(props) => props.theme.colors.primary[300]};
    transform: rotate(180deg);
  }
`;

const Photos = styled.div`
  width: 360px;
  margin-top: 64px;
  margin-inline: auto;
  display: flex;
  flex-direction: row;

  @media (min-width: 768px) {
    width: 900px;
    margin-inline: 0;
  }

  .slick-slider {
    width: 900px;
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .slick-slide {
    padding-inline: 8px;
  }

  .slick-dots {
    text-align: left;
    position: absolute;
    left: -360px;
    top: 250px;
    height: 20px;

    @media (max-width: 768px) {
      position: relative;
      text-align: center;
      left: initial;
      top: initial;
    }

    li {
      width: 8px;
      height: 8px;

      button {
        font-size: 0;
        line-height: 0;
        display: inline-block;
        width: 8px;
        border: 1px solid black;
        border-radius: 50%;
        background: transparent;
        height: 8px;
        padding: 0;
        cursor: pointer;
        outline: 0;

        &:before {
          content: '';
        }
      }
    }

    li.slick-active {
      button {
        background: #000;
      }
    }
  }
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

const SingleBigSlide = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
`;

export {
  Section,
  Uses,
  Header,
  Content,
  Photos,
  PhotosSingle,
  PhotosTitle,
  PhotosDesc,
  UsesSlider,
  SliderContent,
  GatsbyLink,
  SingleBigSlide
};
