import styled from 'styled-components';

export const TitleSection = styled.section`
  padding: 72px 24px;
  margin: 0 auto;
  width: 100%;
  background-color: ${(props) => props.theme.colors.gray!['300']};
  border-bottom: 1px solid ${(props) => props.theme.colors.black};
  text-align: center;

  @media (min-width: ${(props) => props.theme.layout.maxWidth}) {
    padding: 90px 0px;
  }
`;
