import { AnimatedButton } from '@components/shared';
import { IPost } from '@standardTypes';
import { navigate } from 'gatsby';
import React from 'react';

import { truncateString } from '../../../logic';
import * as S from './styles';

const SingleCard: React.FC<IPost> = (post): JSX.Element => {
  const { slug, title, seo, tags } = post;
  const { metaDesc } = seo;

  return (
    <AnimatedButton onClick={() => navigate(`/blog/${slug}`)}>
      <S.Single>
        <S.Image />
        <S.Details>
          <S.Title>{title}</S.Title>
          <S.Description>
            {truncateString(metaDesc as string, 120)}
          </S.Description>
          <S.Tags>
            {tags.nodes.map((tag) => (
              <S.Tag key={tag.name}>{tag.name}</S.Tag>
            ))}
          </S.Tags>
        </S.Details>
      </S.Single>
    </AnimatedButton>
  );
};

export default SingleCard;
