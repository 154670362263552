import React from 'react';
import Slider from 'react-slick';

import PlaceholderSVG from './images/placeholder.svg';
import * as S from './styles';

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        autoplay: true,
        autoplaySpeed: 3000,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

const placeholder = [
  PlaceholderSVG,
  PlaceholderSVG,
  PlaceholderSVG,
  PlaceholderSVG,
  PlaceholderSVG
];
const CompaniesTrust: React.FC = (): JSX.Element => (
  <S.Section>
    <S.Trust>
      <S.Logos>
        {placeholder.map((el) => (
          <S.SingleLogo src={el} />
        ))}
      </S.Logos>
      <S.LogoSlider>
        <Slider {...settings}>
          {placeholder.map((el) => (
            <S.SingleSlide>
              <img src={el} alt="" />
            </S.SingleSlide>
          ))}
        </Slider>
      </S.LogoSlider>
    </S.Trust>
  </S.Section>
);

export default CompaniesTrust;
