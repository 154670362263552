import { Link } from 'gatsby';
import styled from 'styled-components';

const Section = styled.section`
  width: 100%;
  @media (min-width: 1128px) {
    background-color: ${(props) => props.theme.colors.white};
  }
`;

const Reviews = styled.div`
  padding: 72px 24px;
  margin: 0 auto;
  max-width: 1128px;
  width: 100%;

  @media (min-width: 1128px) {
    padding: 72px 0;
    background-color: ${(props) => props.theme.colors.white};
  }
`;
const Header = styled.h2`
  margin-bottom: 64px;
  width: 360px;
`;

const SingleReview = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px;
  width: 360px;
  background: #ffffff;
  border: 1px solid #000000;
  box-sizing: border-box;
  margin-bottom: 48px;
  @media (max-width: 768px) {
    display: flex !important;
    margin: 0 auto;
    width: 312px;
    margin-bottom: 64px;
  }
`;

const ReviewContent = styled.p`
  width: 296px;
  /* font-family: HK Grotesk; */
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  margin: 24px 0px;
  @media (max-width: 768px) {
    width: 254px;
  }
`;

const Author = styled.h5`
  width: 264px;
  /* font-family: HK Grotesk; */
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
`;

const RatingSlider = styled.div`
  width: 1128px;
  margin: 0 auto;
  .slick-slide {
    padding: 0 10px;
  }
  .slick-dots {
    text-align: center;
    li {
      width: 8px;
      height: 8px;

      button {
        font-size: 0;
        line-height: 0;
        display: inline-block;
        width: 8px;
        border: 1px solid black;
        border-radius: 50%;
        background: transparent;
        height: 8px;
        padding: 0;
        cursor: pointer;
        outline: 0;

        &:before {
          content: '';
        }
      }
    }
    li.slick-active {
      button {
        background: #000;
      }
    }
  }
  @media (max-width: 768px) {
    max-width: 100%;
    width: 100%;
  }
`;

export {
  Section,
  Reviews,
  Header,
  SingleReview,
  RatingSlider,
  ReviewContent,
  Author
};
