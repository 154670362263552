/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { IPost } from '@standardTypes';
import React, { useState, useEffect } from 'react';

import * as S from './styles';

interface ArticleProps {
  data: IPost;
}
const Article: React.FC<ArticleProps> = ({ data }): JSX.Element => {
  const [isMobile, setIsMobile] = useState(false);
  const { title, content, slug, categories } = data;
  const { sourceUrl } = data.featuredImage.node;

  const handleResize = () => {
    if (window.innerWidth < 720) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
  }, []);

  const characterCount = isMobile ? 100 : 300;

  return (
    <S.GatsbyLink
      to={`/${categories.nodes
        .slice(0)
        .reverse()
        .map((category) => `${category.slug}/`)
        .join('')}${slug}`}
    >
      <S.Article>
        <S.Image src={sourceUrl} />
        <div>
          <S.Title>{title}</S.Title>
          <S.Description>
            {`${content.substring(0, characterCount)} ...`}
          </S.Description>
        </div>
      </S.Article>
    </S.GatsbyLink>
  );
};

export default Article;
