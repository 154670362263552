import React from 'react';

import * as S from './styles';

const RemindPasswordTitle = (): JSX.Element => (
  <S.TitleSection>
    <h1>Zresetuj swoje hasło</h1>
  </S.TitleSection>
);

export default RemindPasswordTitle;
