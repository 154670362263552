import { Form } from 'formik';
import styled from 'styled-components';

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  margin: 64px auto 72px;
  max-width: 360px;
  padding: 0 24px;

  @media (min-width: ${(props) => props.theme.layout.maxWidth}) {
    padding: 0;
  }
`;

export const Text = styled.div`
  padding-top: 8px;
  margin-bottom: 32px;
`;

export const Success = styled.p`
  margin-top: 20px;
  text-align: center;
  color: #00c853;
`;

export const Failed = styled.p`
  margin-top: 20px;
  text-align: center;
  color: #ed3103;
`;
