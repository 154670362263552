import React from 'react';

import ArrowSVG from '../../icons/ArrowSVG';
import BgImage from '../../images/FeaturedTextBgImage.png';
import * as S from './styles';

const FeaturedText: React.FC = (): JSX.Element => (
  <S.FeaturedText>
    <S.FeaturedImage src={BgImage} />
    <S.Information>
      <S.Header>
        “Lorem ipsum dolor sit amet, consectetur adipiscing elit”
      </S.Header>
      <S.Description>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc at
          venenatis egestas amet. Vitae aliquam blandit arcu, amet urna,
          tincidunt. Pellentesque velit eget diam adipiscing neque, elit arcu.
          Nisl, mi facilisis porta eu, euismod id molestie auctor nulla.
        </p>
      </S.Description>
      <S.GatsbyLink to="#">
        Dowiedz się więcej
        <ArrowSVG />
      </S.GatsbyLink>
    </S.Information>
  </S.FeaturedText>
);

export default FeaturedText;
