import { Pagination } from '@components/shared';
import { IPost } from '@standardTypes';
import React, { useEffect, useState } from 'react';

import Post from './Post';
import * as S from './styles';

interface UsesPostsProps {
  posts: IPost[];
  pageParam: string;
}
const UsesPosts: React.FC<UsesPostsProps> = ({
  posts,
  pageParam
}): JSX.Element => {
  const [isDataChanged, setIsDataChanged] = useState(false);

  useEffect(() => {
    setIsDataChanged(true);
  }, [posts]);

  const changeDataChanged = () => {
    setIsDataChanged(false);
  };

  return (
    <S.Section>
      <S.UsesPosts>
        <Pagination
          data={posts}
          RenderComponent={Post}
          pageLimit={1}
          dataLimit={6}
          pageParam={pageParam}
          changeDataChanged={changeDataChanged}
          isDataChanged={isDataChanged}
        />
      </S.UsesPosts>
    </S.Section>
  );
};

export default UsesPosts;
