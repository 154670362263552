import { Link } from 'gatsby';
import styled from 'styled-components';

const Tags = styled.ul`
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 16px;
    margin-top: 64px;
`;

const Tag = styled.li`
    margin-bottom: 16px;
`;

const Badge = styled.small`
    position: absolute;
    top: 7px;
    right: -12px;
    color: #111;
    background-color: ${(props) => props.theme.colors.white};
    border: 1px solid ${(props) => props.theme.colors.gray[300]};
    border-radius: 50%;
    width: 24px;
    height: 24px;
    text-align: center;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const GatsbyLink = styled(Link)`
    color: ${(props) => props.theme.colors.gray[700]};
    border: 1px solid ${(props) => props.theme.colors.gray[300]};
    padding: 8px 16px;
    position: relative;
`;

export { Tag, Tags, Badge, GatsbyLink };
