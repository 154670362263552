/* eslint-disable @typescript-eslint/no-unsafe-call */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Pagination } from '@components/shared';
import { IPost, ICategory } from '@standardTypes';
import React, { useState, useEffect } from 'react';

import Article from './Article';
import * as S from './styles';

interface KnowledgePostsProps {
  posts: IPost[];
  pageParam: string;
  subcategories: ICategory;
}

const ArticleList: React.FC<KnowledgePostsProps> = ({
  posts,
  pageParam,
  subcategories
}): JSX.Element => {
  const [isDataChanged, setIsDataChanged] = useState(false);
  const [sortedPosts, setSortedPosts] = useState(posts);
  const [isActive, setActive] = useState(false);

  const sortBySubcategory = (id: string) => {
    setActive(id);
    setIsDataChanged(true);
    setSortedPosts(
      posts.filter((post) =>
        post.categories.nodes.some((subcategory) => subcategory.id === id)
      )
    );
  };

  useEffect(() => {
    setIsDataChanged(true);
  }, []);

  const setDefaultData = () => {
    setIsDataChanged(true);
    setSortedPosts(posts);
    setActive(false);
  };

  const changeDataChanged = () => {
    setIsDataChanged(false);
  };

  return (
    <S.Section>
      <S.ArticlesList>
        <S.SubcategoriesWrapper>
          <S.GatsbyLink to="#" onClick={() => setDefaultData()}>
            Wszystkie
          </S.GatsbyLink>
          {subcategories.map(({ name, id }) => (
            <S.GatsbyLink
              key={id}
              to="#"
              className={isActive === id ? 'active' : null}
              onClick={() => sortBySubcategory(id)}
            >
              {name}
            </S.GatsbyLink>
          ))}
        </S.SubcategoriesWrapper>
        <Pagination
          data={sortedPosts}
          RenderComponent={Article}
          pageLimit={1}
          dataLimit={4}
          pageParam={pageParam}
          changeDataChanged={changeDataChanged}
          isDataChanged={isDataChanged}
        />
      </S.ArticlesList>
    </S.Section>
  );
};

export default ArticleList;
