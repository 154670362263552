import React from 'react';
import Slider from 'react-slick';

import ArrowSVG from '../../icons/ArrowSVG';
import Placeholder from './images/placeholder.png';
import * as S from './styles';

const data = [
  {
    img: Placeholder,
    title: 'Lorem ipsum dolor sit amet',
    subtitle:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit'
  },
  {
    img: Placeholder,
    title: 'Lorem ipsum dolor sit amet',
    subtitle:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit'
  },
  {
    img: Placeholder,
    title: 'Lorem ipsum dolor sit amet',
    subtitle:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit'
  }
];
const settings = {
  arrows: false,
  infinite: true,
  slidesToShow: 2,
  autoplay: true,
  autoplaySpeed: 5000,
  speed: 2000,
  slidesToScroll: 1,
  dots: true,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        centerMode: false,
        autoplay: true,
        infinite: true,
        autoplaySpeed: 3000,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};
const PopularUses: React.FC = (): JSX.Element => (
  <S.Section>
    <S.Uses>
      <S.Content>
        <S.Header>Popularne zastosowania</S.Header>
        <S.UsesSlider>
          <S.SliderContent>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc at
            venenatis egestas amet. Vitae aliquam blandit arcu, amet urna,
            tincidunt.
          </S.SliderContent>
        </S.UsesSlider>
        <S.GatsbyLink to="/zastosowania">
          Zobacz więcej zastosowań
          <ArrowSVG />
        </S.GatsbyLink>
      </S.Content>
      <S.Photos>
        <Slider {...settings}>
          {data.map((el) => (
            <S.PhotosSingle key={el.title}>
              <img alt="" src={el.img} />
              <S.PhotosTitle>{el.title}</S.PhotosTitle>
              <S.PhotosDesc>{el.subtitle}</S.PhotosDesc>
            </S.PhotosSingle>
          ))}
        </Slider>
      </S.Photos>
    </S.Uses>
  </S.Section>
);
export default PopularUses;
