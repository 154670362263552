import styled from 'styled-components';

export const AppointmentContent = styled.div`
  padding: 12px;
  text-align: left;

  @media (min-width: 750px) {
    padding: 32px;
  }
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 750px) {
    flex-direction: row;
    justify-content: space-between;

    div {
      width: 47.5%;
    }
  }
`;

export const FormDescription = styled.p`
  margin-bottom: 32px;
`;

export const CalendarContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;

  @media (min-width: 750px) {
    flex-direction: row;
    justify-content: space-evenly;
    align-items: start;
  }
`;

export const SuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 60px;
    height: 60px;
  }
`;

export const SuccessInfo = styled.h4`
  color: #00c853;
  margin: 26px 0px 16px 0px;
`;

export const ErrorInfo = styled.h4`
  margin-bottom: 26px;
  color: #ed3103;
  text-align: center;
`;

export const ErrorMessage = styled.p`
  margin-bottom: 18px;
  color: ${(props) => props.theme.colors.primary[300]};
  text-align: center;
`;
