// eslint-disable react/jsx-one-expression-per-line
import {
  addMonths,
  subMonths,
  startOfMonth,
  isSameMonth,
  endOfWeek,
  addDays
} from 'date-fns';
import React, { useEffect, useState } from 'react';

import { weekdaysNames, takeMonth } from '../utils';
import CalendarHandler from './CalendarHandler';
import * as S from './styles';

interface CalendarProps {
  chosenDate: Date;
  setChosenDate: React.Dispatch<React.SetStateAction<Date>>;
  setNewDate: (field: string, value: string | Date) => void;
}

const getStartOfTheMonthDay = (chosenDate: Date) => {
  const result = startOfMonth(chosenDate).getDay();
  return result === 0 ? 7 : result;
};

const isWeekValid = (chosenDate: Date, firstDayOfWeek: Date) =>
  isSameMonth(chosenDate, firstDayOfWeek) ||
  isSameMonth(chosenDate, endOfWeek(firstDayOfWeek, { weekStartsOn: 1 }));

const getFirstAvailableDay = (chosenDate: Date) => {
  const result = chosenDate.getDay();
  if (result === 6) {
    return addDays(chosenDate, 2);
  }
  if (result === 0) {
    return addDays(chosenDate, 1);
  }
  return chosenDate;
};

const Calendar: React.FC<CalendarProps> = ({
  chosenDate,
  setChosenDate,
  setNewDate
}): JSX.Element => {
  const [chosenMonth, setChosenMonth] = useState<Array<Array<Date>>>(
    takeMonth(chosenDate)
  );

  const handleDate = (value: string) => {
    const dateFn = value === 'next' ? addMonths : subMonths;
    setChosenDate((prev: Date) =>
      getFirstAvailableDay(startOfMonth(dateFn(prev, 1)))
    );
    setNewDate('callDate', '');
  };

  useEffect(() => {
    const month = takeMonth(chosenDate);
    setChosenMonth(month);
  }, [chosenDate]);

  return (
    <S.CalendarContainer>
      <CalendarHandler handleDate={handleDate} chosenDate={chosenDate} />
      <div>
        <S.WeekdaysContainer>
          {weekdaysNames.map((el) => (
            <p key={el}>{el}</p>
          ))}
        </S.WeekdaysContainer>
        {chosenMonth.map((week, index) => {
          const isValidWeek = isWeekValid(chosenDate, week[0]);

          if (!isValidWeek) return null;

          return (
            <S.SingleWeekContainer key={week.toString()}>
              {week.map((day) => {
                const isMonthTheSame = isSameMonth(day, chosenDate);
                return isMonthTheSame ? (
                  <S.SingleDayContainer
                    onClick={() => {
                      setChosenDate(day);
                      setNewDate('callDate', day);
                    }}
                    startingDay={
                      index === 0 ? getStartOfTheMonthDay(chosenDate) : 1
                    }
                    isActive={chosenDate.getDate() === day.getDate()}
                    type="button"
                    disabled={day.getDay() === 6 || day.getDay() === 0}
                    key={day.toString()}
                  >
                    {day.getDate()}
                  </S.SingleDayContainer>
                ) : null;
              })}
            </S.SingleWeekContainer>
          );
        })}
      </div>
    </S.CalendarContainer>
  );
};

export default Calendar;
