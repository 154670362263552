import { Link } from 'gatsby';
import styled from 'styled-components';





const Section = styled.section`
  border-bottom: 1px solid ${(props) => props.theme.colors.black};
`;

const Trust = styled.div`
  margin: 0 auto;
  max-width: 1440px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Header = styled.h2`
  margin: 72px auto 64px auto;
  font-weight: 400;
`;

const Logos = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 1128px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 72px;

  @media (max-width: 768px) {
    display: none;
  }
`;
const SingleLogo = styled.img`
  margin: 0 16px;
`;

const LogoSlider = styled.div`
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 64px;
  .slick-dots {
    text-align: center;
    li {
      width: 8px;
      height: 8px;

      button {
        font-size: 0;
        line-height: 0;
        display: inline-block;
        width: 8px;
        border: 1px solid black;
        border-radius: 50%;
        background: transparent;
        height: 8px;
        padding: 0;
        cursor: pointer;
        outline: 0;

        &:before {
          content: '';
        }
      }
    }
    li.slick-active {
      button {
        background: #000;
      }
    }
  }

  @media (min-width: 768px) {
    display: none;
  }
`;

const SingleSlide = styled.div`
    width:90%;
    display: flex !important;
    margin-bottom: 64px;
    img{
        margin: 0 auto;
    }
`


export { Trust, Header, Logos, SingleLogo, LogoSlider, SingleSlide, Section };