import styled from 'styled-components';

const Section = styled.section`
  padding: 72px 24px;
  border-bottom: 1px solid ${(props) => props.theme.colors.black};

  @media (min-width: ${(props) => props.theme.layout.maxWidth}) {
  }
`;

const Title = styled.h2`
  text-align: center;
  margin-bottom: 64px;
  font-weight: 400;

  @media (min-width: ${(props) => props.theme.layout.maxWidth}) {
    max-width: 550px;
    margin-inline: auto;
  }
`;

const Text = styled.p`
  text-align: center;

  @media (min-width: ${(props) => props.theme.layout.maxWidth}) {
    max-width: 550px;
    margin-inline: auto;
  }
`;

export { Section, Title, Text };
