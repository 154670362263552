/* eslint-disable react/jsx-curly-newline */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */

/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { WPApi } from '@api';
import { TextInput, RedButton } from '@components/shared';
import { Formik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';

import * as S from './styles';

const formSchema = Yup.object().shape({
  email: Yup.string()
    .required('To pole jest wymagane')
    .email('Wpisz prawidłowy email')
});

const initialValues = {
  email: ''
};

const ForgotPasswordForm: React.FC = (): JSX.Element => {
  const [response, setResponse] = useState({ message: '', success: false });

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={formSchema}
        onSubmit={(values, { resetForm }) =>
          WPApi.requestPasswordChangeLink(values)
            .then((data) => {
              setResponse({ message: data.message, success: true });
              resetForm();
            })
            .catch((data) => {
              setResponse({ message: data.data.message, success: false });
            })
        }
      >
        {({ errors, touched }) => (
          <S.StyledForm>
            <TextInput
              name="email"
              type="email"
              isInvalid={Boolean(errors.email && touched.email)}
              placeholder="Adres e-mail"
            />
            <RedButton type="submit">Wyślij link do zmiany hasła</RedButton>
            {response.message && response.success === true ? (
              <S.Success>{response.message}</S.Success>
            ) : (
              <S.Failed>{response.message}</S.Failed>
            )}
          </S.StyledForm>
        )}
      </Formik>
    </div>
  );
};

export default ForgotPasswordForm;
