/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { WPApi } from '@api';
import {
  LoadingButton,
  TextInput,
  TextareaInput,
  CheckboxInput
} from '@components/shared';
import { Formik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';

import * as S from './styles';

const formSchema = Yup.object().shape({
  name: Yup.string()
    .required('To pole jest wymagane')
    .min(2, 'Imię powinno mieć co najmniej 2 znaki'),
  surname: Yup.string()
    .required('To pole jest wymagane')
    .min(2, 'Nazwisko powinno mieć co najmniej 2 znaki'),
  email: Yup.string()
    .required('To pole jest wymagane')
    .email('Wpisz prawidłowy email'),
  message: Yup.string()
    .required('To pole jest wymagane')
    .min(15, 'Wiadomość powinna mieć co najmniej 15 znaków'),
  privacy: Yup.boolean()
    .test('is valid', 'To pole jest wymagane', (value) => value === true)
    .required('To pole jest wymagane')
});

const initialValues = {
  name: '',
  surname: '',
  email: '',
  message: '',
  privacy: false
};

const FormikForm = (): JSX.Element => {
  const [finalMessage, setFinalMessage] = useState({ status: '', message: '' });
  const [isBusy, setIsBusy] = useState(false);

  const onSubmit = (
    values: typeof initialValues,
    { resetForm }: { resetForm: () => void }
  ) => {
    setIsBusy(true);
    WPApi.sendMessage(values.name, values.surname, values.email, values.message)
      .then((data: any) => {
        const { message, status } = data;
        setFinalMessage({ message, status });
        setIsBusy(false);
      })
      .catch((err) => {
        console.log(err);
        setIsBusy(false);
      });

    resetForm();
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={formSchema}
      onSubmit={onSubmit}
    >
      {({ errors, touched, setFieldValue }) => (
        <S.StyledForm>
          <TextInput
            name="name"
            isInvalid={Boolean(errors.name && touched.name)}
            placeholder="Imię"
          />
          <TextInput
            name="surname"
            isInvalid={Boolean(errors.surname && touched.surname)}
            placeholder="Nazwisko"
          />
          <TextInput
            name="email"
            type="email"
            isInvalid={Boolean(errors.email && touched.email)}
            placeholder="Adres e-mail"
          />
          <TextareaInput
            name="message"
            isInvalid={Boolean(errors.message && touched.message)}
            placeholder="Wiadomość"
          />
          <CheckboxInput
            setFieldValue={setFieldValue}
            isInvalid={Boolean(errors.privacy && touched.privacy)}
            name="privacy"
          >
            <label htmlFor="privacy">
              Twoje dane osobowe zostaną użyte do obsługi twojej wizyty na
              naszej stronie, zarządzania dostępem do twojego konta i dla innych
              celów o których mówi nasza polityka prywatności.
            </label>
          </CheckboxInput>
          <S.ButtonContainer>
            <LoadingButton
              type="submit"
              text="Wyślij wiadomość"
              color="red"
              isBusy={isBusy}
            />
          </S.ButtonContainer>
          {finalMessage.message && (
            <S.MessageContainer>{finalMessage.message}</S.MessageContainer>
          )}
        </S.StyledForm>
      )}
    </Formik>
  );
};

export default FormikForm;
