/* eslint-disable @typescript-eslint/no-unsafe-return */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */

/* eslint-disable @typescript-eslint/no-unsafe-member-access */

/* eslint-disable @typescript-eslint/no-unsafe-call */
import { WPApi } from '@api';
import { AuthCtx, useActions, useContextState } from '@components/contexted';
import { TextInput } from '@components/shared';
import { Formik } from 'formik';
import countries from 'i18n-iso-countries';
import plLocale from 'i18n-iso-countries/langs/pl.json';
import Cookies from 'js-cookie';
import React, { useState } from 'react';
import * as Yup from 'yup';

import * as S from './styles';

const formSchema = Yup.object().shape({
  first_name: Yup.string()
    .required('To pole jest wymagane')
    .min(2, 'Imię powinno mieć co najmniej 2 znaki'),
  last_name: Yup.string()
    .required('To pole jest wymagane')
    .min(2, 'Nazwisko powinno mieć co najmniej 2 znaki'),
  email: Yup.string()
    .required('To pole jest wymagane')
    .email('Wpisz prawidłowy email'),
  phone: Yup.string()
    .required('To pole jest wymagane')
    .min(7, 'Numer powinien mieć co najmniej 7 znaków')
    .matches(new RegExp('[0-9]{7}')),
  city: Yup.string().required('To pole jest wymagane'),
  country: Yup.string().required('To pole jest wymagane')
});

const UserDetails = (): JSX.Element => {
  const data = useContextState(AuthCtx);
  const token = Cookies.get('token');
  const { logOut } = useActions(AuthCtx, ['logOut']);

  const [selectedCountry, setSelectedCountry] = useState({
    label: 'Polska',
    value: 'PL'
  });

  const selectCountryHandler = (value, label) => {
    setSelectedCountry({
      label,
      value
    });
  };

  countries.registerLocale(plLocale);

  const countryObj = countries.getNames('pl', { select: 'official' });

  const countryArr = Object.entries(countryObj).map(([key, value]) => ({
    label: value,
    value: key
  }));

  const selectInputStyles = {
    option: (provided, state) => ({
      ...provided,
      'background': state.isSelected ? '#FC4E24' : '#FFFFFF',

      '&:hover': {
        background: '#fc4e2438',
        color: '#000000'
      }
    }),
    control: () => ({
      display: 'flex'
    }),
    menu: (provided) => ({
      ...provided,
      margin: '5px 0 5px -3px',
      padding: '5px 0',
      borderRadius: 0,
      border: '1px solid black'
    })
  };

  return (
    <S.FormWrapper>
      <Formik
        enableReinitialize
        initialValues={data}
        validationSchema={formSchema}
        onSubmit={
          () =>
            WPApi.getUserInfo(token, data.id)
              .then((res) => {
                console.log(res);
                // Cookies.set('token', token, { expires: 7 });
                // setUserDetails(data);
                // setResponse({
                //   message: 'Pomyślnie zalogowano',
                //   success: true
                // });
                // resetForm();
              })
              .catch((err) => {
                console.log(err);
              })
          // eslint-disable-next-line react/jsx-curly-newline
        }
      >
        {({ errors, touched }) => (
          <S.StyledForm>
            {/* <S.Label htmlFor="first_name">Imię:</S.Label> */}
            <TextInput
              name="first_name"
              type="text"
              isInvalid={Boolean(errors.first_name && touched.first_name)}
              placeholder="Imię"
            />
            {/* <S.Label htmlFor="last_name">Nazwisko:</S.Label> */}
            <TextInput
              name="last_name"
              type="text"
              isInvalid={Boolean(errors.last_name && touched.last_name)}
              placeholder="Nazwisko"
            />
            {/* <S.Label htmlFor="email">E-mail:</S.Label> */}
            <TextInput
              name="email"
              type="email"
              isInvalid={Boolean(errors.email && touched.email)}
              placeholder="E-mail"
            />
            {/* <S.Label htmlFor="phone">Numer telefonu:</S.Label> */}
            <TextInput
              name="phone"
              type="tel"
              isInvalid={Boolean(errors.phone && touched.phone)}
              placeholder="Numer telefonu"
            />
            {/* <S.Label htmlFor="city">Miasto:</S.Label> */}
            <TextInput
              name="city"
              type="text"
              isInvalid={Boolean(errors.city && touched.city)}
              placeholder="Miasto"
            />
            {/* <S.Label htmlFor="country">Kraj:</S.Label> */}
            {/* <TextInput
              name="country"
              type="text"
              isInvalid={Boolean(errors.country && touched.country)}
              placeholder="Kraj"
            /> */}
            <S.SelectInput
              name="country"
              styles={selectInputStyles}
              isInvalid={Boolean(errors.country && touched.country)}
              defaultValue={selectedCountry}
              onChange={(e) => selectCountryHandler(e.value, e.label)}
              options={countryArr}
            />
            <S.Button type="submit">Zmień dane</S.Button>
            <S.LogoutButton type="submit" onClick={() => logOut()}>
              Wyloguj
            </S.LogoutButton>
          </S.StyledForm>
        )}
      </Formik>
    </S.FormWrapper>
  );
};

export default UserDetails;
