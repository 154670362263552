import styled from 'styled-components';

export const CalendarContainer = styled.div`
  width: 100%;

  @media (min-width: 1128px) {
    width: 300px;
  }
`;

export const WeekdaysContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 6px;
  align-items: center;
  margin-top: 16px;

  p {
    font-size: 9px;
    line-height: 12px;
    text-align: center;
    justify-self: center;
  }
`;

export const SingleWeekContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 6px;
  margin-top: 16px;
`;

interface SingleDayContainerProps {
  isActive: boolean;
  startingDay: number;
}

export const SingleDayContainer = styled.button<SingleDayContainerProps>`
  justify-self: center;
  width: 24px;
  height: 24px;
  border: none;
  cursor: pointer;
  text-align: center;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: ${(props) => (props.isActive ? `#fff` : `#fc4e24`)};
  background-color: ${(props) => (props.isActive ? `#FC4E24` : `#ffede9`)};

  @media (min-width: 335px) {
    width: 32px;
    height: 32px;
    font-size: 16px;
  }

  &:first-child {
    grid-column: ${(props) => props.startingDay};
  }

  &[disabled],
  &:disabled {
    background: transparent;
    color: #000;
    cursor: default;
  }
`;
