import styled from 'styled-components';

export const Section = styled.section`
  width: 100%;
  background-color: ${(props) => props.theme.colors.white};
  border-bottom: 1px solid ${(props) => props.theme.colors.black};
  text-align: left;
  padding: 72px 24px;

  @media (min-width: 1128px) {
    padding: 72px 156px 124px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  max-width: 650px;

  @media (min-width: 1128px) {
    flex-direction: row;
    max-width: 100%;
  }
`;

export const SectionTitle = styled.h2`
  font-weight: 400;
  margin-bottom: 64px;
`;

export const SectionDescriptionContainer = styled.div`
  margin-bottom: 64px;

  @media (min-width: 1128px) {
    width: 356px;
    margin-right: 26px;
    margin-bottom: 0px;

    p {
      width: 261px;
    }
  }
`;

export const ShowroomsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 1128px) {
    flex-direction: row;
    flex-grow: 1;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 64px;
  }
`;

export const SingleShowroom = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  margin-bottom: 32px;

  &:last-of-type {
    margin-bottom: 0px;
  }

  @media (min-width: 1128px) {
    margin-bottom: 0px;
    width: 43%;
  }
`;
