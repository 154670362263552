import styled from 'styled-components';

export const HandlerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const LeftArrow = styled.img`
  transform: rotate(90deg);
`;

export const RightArrow = styled.img`
  transform: rotate(-90deg);
`;

export const ActualDate = styled.p`
  font-weight: 600;
`;

export const HandlerButton = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
`;
