import { WPApi } from '@api';
import { TextInput, RedButton } from '@components/shared';
import { Formik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';

import * as S from './styles';

const formSchema = Yup.object().shape({
  name: Yup.string()
    .required('To pole jest wymagane')
    .min(2, 'Imię powinno mieć co najmniej 2 znaki'),
  surname: Yup.string()
    .required('To pole jest wymagane')
    .min(2, 'Nazwisko powinno mieć co najmniej 2 znaki'),
  email: Yup.string()
    .required('To pole jest wymagane')
    .email('Wpisz prawidłowy email'),
  password: Yup.string()
    .required('To pole jest wymagane.')
    .min(6, 'Hasło musi zawierać minimum 6 znaków'),
  // .matches(
  //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])/, // eslint-disable-line
  //   'Hasło musi zawierać małą i wielką literę, cyfrę oraz znak specjalny'
  // ),
  passwordConfirmation: Yup.string()
    .required('To pole jest wymagane.')
    .oneOf([Yup.ref('password'), null], 'Podane hasła nie są takie same')
});

const initialValues = {
  name: '',
  surname: '',
  email: '',
  password: '',
  passwordConfirmation: ''
};

interface IDataSuccess {
  code: number;
  message: string;
}

interface IDataError {
  data: {
    code: number;
    message: string;
    data: {
      status: number;
    };
  };
  status: number;
}
const RegistrationForm = (): JSX.Element => {
  const [response, setResponse] = useState({
    message: '',
    code: 0
  });
  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={formSchema}
        onSubmit={
          (values, { resetForm }) =>
            WPApi.registerNewUser(values)
              .then((data: IDataSuccess) => {
                setResponse({
                  message: data.message,
                  code: data.code
                });
                resetForm();
              })
              .catch((data: IDataError) => {
                setResponse({
                  message: data.data.message,
                  code: data.data.code
                });
              })
          // eslint-disable-next-line react/jsx-curly-newline
        }
      >
        {({ errors, touched }) => (
          <S.StyledForm>
            <TextInput
              name="name"
              isInvalid={Boolean(errors.name && touched.name)}
              placeholder="Imię"
            />
            <TextInput
              name="surname"
              isInvalid={Boolean(errors.surname && touched.surname)}
              placeholder="Nazwisko"
            />
            <TextInput
              name="email"
              type="email"
              isInvalid={Boolean(errors.email && touched.email)}
              placeholder="Adres e-mail"
            />
            <TextInput
              name="password"
              type="password"
              isInvalid={Boolean(errors.password && touched.password)}
              placeholder="Hasło"
            />
            <TextInput
              name="passwordConfirmation"
              type="password"
              isInvalid={Boolean(
                errors.passwordConfirmation && touched.passwordConfirmation
              )}
              placeholder="Powtórz hasło"
            />
            <S.Text>
              Twoje dane osobowe zostaną użyte do obsługi twojej wizyty na
              naszej stronie, zarządzania dostępem do twojego konta i dla innych
              celów o których mówi nasza polityka prywatności.
            </S.Text>
            <RedButton type="submit">Zarejestruj się</RedButton>
            {response.message && response.code === 200 ? (
              <S.Success>{response.message}</S.Success>
            ) : (
              <S.Failed>{response.message}</S.Failed>
            )}
          </S.StyledForm>
        )}
      </Formik>
    </div>
  );
};

export default RegistrationForm;
