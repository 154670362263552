import type { IPost } from '@standardTypes';
import React from 'react';

import SingleCard from './SingleCard';
import * as S from './styles';

interface PostsProps {
  posts: Array<IPost>;
}

const Posts: React.FC<PostsProps> = ({ posts }): JSX.Element => (
  <S.Posts>
    {posts.map((post) => (
      <SingleCard key={post.slug} {...post} />
    ))}
  </S.Posts>
);

export default Posts;
