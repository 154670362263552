import React from 'react';

import ArrowSVG from '../../icons/arrow.svg';
import { monthsNames } from '../../utils';
import * as S from './styles';

const getCalendarTitle = (chosenDate: Date) =>
  `${monthsNames[chosenDate.getMonth()]} ${chosenDate.getFullYear()}`;

interface CalendarHandlerProps {
  handleDate: (value: string) => void;
  chosenDate: Date;
}

const CalendarHandler: React.FC<CalendarHandlerProps> = ({
  handleDate,
  chosenDate
}): JSX.Element => (
  <S.HandlerContainer>
    <S.HandlerButton onClick={() => handleDate('prev')} type="button">
      <S.LeftArrow src={ArrowSVG} alt="left-arrow" />
    </S.HandlerButton>
    <S.ActualDate>{getCalendarTitle(chosenDate)}</S.ActualDate>
    <S.HandlerButton onClick={() => handleDate('next')} type="button">
      <S.RightArrow src={ArrowSVG} alt="right-arrow" />
    </S.HandlerButton>
  </S.HandlerContainer>
);

export default CalendarHandler;
