import styled from 'styled-components';

export const Container = styled.div`
  margin: 30px auto 0px auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 750px) {
    width: 160px;
    margin: 0px 0px 0px 30px;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
`;

interface StyledButtonProps {
  isActive: boolean;
}

export const StyledButton = styled.button<StyledButtonProps>`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  cursor: pointer;

  border-radius: 0px;
  border: 1px solid #c4c4cd;
  padding: 14px 59px;
  background: ${(props) => (props.isActive ? '#FC4E24' : 'transparent')};
  color: ${(props) => (props.isActive ? '#fff' : '#000')};

  &[disabled],
  &:disabled {
    background: #ffede9;
    cursor: default;
  }
`;
